import { Grid, Typography } from "@mui/material";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

interface SentimentViewProps{
    hasEnoughBalance: boolean,
    address: string,
    copeBalance: number
}

export default function SentimentView(props:SentimentViewProps) {
    //portfolio-symbols
    const [watchList, setWatchlist] = React.useState([]);



    const fetchData = async () => {
        if(props.hasEnoughBalance){
            const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/portfolio-symbols');
            setWatchlist(response);
    
            console.log('symbols', response);
        }else{}

    }

    useEffect(() => {

        fetchData();

    }, [props.hasEnoughBalance]);

    return (

        
        <div>

            <Typography>
                
                Technical Indicator Based Market Sentiment For Leaderboard Calls
                
                
            </Typography>

            <Typography>
                &nbsp;
            </Typography>

            <Grid container gap={2}>

                {watchList.map((w: string) => {

                    return <Grid item>
                        <TechnicalAnalysis
                            symbol={w.replace("/", "")}
                            colorTheme="dark" />
                    </Grid>
                })
                }

                {!props.hasEnoughBalance ? <div>Connect wallet to view this page. You must also have 50 COPE or more to view this page. Your balance is : {props.copeBalance}</div> : <div></div>}


            </Grid>
        </div>
    )





}