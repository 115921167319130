
import Chart from 'react-apexcharts'
import {
  CRow,
  CCol,
  CWidgetStatsA,
} from '@coreui/react'


interface TreeMapProps{
  data:any
}

export default function  TreeMapMarketCap(props:TreeMapProps){

  const numberWithCommas = (x:number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } 

    const options = {
        
      chart: {
        toolbar: {
          show: false
        }
        },
        legend: {
          show: false
        },
        title: {
          text: 'Portfolio Market Caps',
          style:{
            color:'white'
          },
        },
        tooltip: {  
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          theme:'dark',
          y: {
            formatter: (v:number) => '$'+numberWithCommas(v),
            title: {
              formatter: () => 'Market Cap',
            },
          },
          z: {
            formatter: (v:number) => v!=null?v.toFixed(2):v,
            title: 'ROI %'
          },
        }
      }


      const series= [
        {
          data: props.data
        }
      ]


    

    return (
        <CRow>
          <CCol>
          <Chart options={options} series={series} type="treemap" width='100%' height='500' />
          </CCol>
        </CRow>
           
    )




}