import { Grid, Typography, Box, Slider, Button, Paper, ListItemButton, Checkbox, ListItemIcon, ListItemText} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap'
import ReactDOM from "react-dom";
import axios from 'axios';
import _ from 'lodash';
import { fontWeight } from "@mui/system";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { NONAME } from "dns";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

interface CorrelationMatrixProps {
    hasEnoughBalance: boolean;
    source:string
}


export default function CorrelationMatrix(props: CorrelationMatrixProps) {
    const theme = useTheme();
    const [xLabels, setXLabels] = useState([]);
    const [yLabels, setYLabels] = useState([]);
    const [labelsForSelect, setLabelsForSelect] = useState([]);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(0);
    const [result, setResult] = useState([]);
    const [labelFontSize, setLabelFontSize] = useState('.65rem');
    const [cellHeightVal, setCellHeightVal] = useState('4.5rem');
    const [symbolsFilter, setSymbolsFilter] = useState<string[]>([]);
    const [checked, setChecked] = React.useState<string[]>([]);
    const [value, setValue] = React.useState(0);
    const [uncorrelatedList, setUncorrelatedList] = React.useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [title, setTile] = useState('');

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const applyFilter = (filterVal: number) => {

        try {

            if (filter) {
                let filtered = result.filter((c: any) => {
                    if (filterVal >= 0.8) {
                        return Number(c.correlation) >= Number(filterVal)
                    } else { }

                    if (filterVal <= -0.8) {
                        return Number(c.correlation) <= Number(filterVal) || Number(c.correlation) >= Number(0.8)
                    } else { }

                    return true;

                });

                const xyLabels: any = _.map(_.groupBy(filtered, 'symbol1'), (v, k) => {
                    return k;
                });

                const grouped = _.groupBy(filtered, 'symbol1');

                let corrData: any = [];
                _.map(grouped, (array, key) => {

                    corrData.push(_.map(array, 'correlation'));
                })


                setXLabels(xyLabels);
                setYLabels(xyLabels);

                setData(corrData);


            }


        } catch (e) {
            console.log(e);
        }


    }

    const buildMatrix = (matrixData: Array<any>, filters: Array<string>) => {
        try {
           
            let filteredMatrixData = new Array();

            let xyLabels: any = _.map(_.groupBy(matrixData, 'symbol1'), (v, k) => {
                return k;

            });

            setLabelsForSelect(xyLabels);

            // if ((labelsForSelect == null || labelsForSelect.length == 0) && xyLabels!=null && xyLabels.length>0) {
            //     console.log('xyLabels..', xyLabels);
            //     setLabelsForSelect(xyLabels);
            // } else { }


            

            if (filters != null && filters.length > 0) {

                filteredMatrixData = matrixData.filter((m) => {
                    //return (m.correlation == 1 || m.correlation <=-0.8)
                    return (filters.includes(m.symbol1) && filters.includes(m.symbol2));
                })

            } else {

                filteredMatrixData = matrixData;
            }

            xyLabels = _.map(_.groupBy(filteredMatrixData, 'symbol1'), (v, k) => {
                return k;

            });
            

            setXLabels(xyLabels);
            setYLabels(xyLabels);



            const grouped = _.groupBy(filteredMatrixData, 'symbol1');


            let corrData: any = [];
            _.map(grouped, (array, key) => {

                corrData.push(_.map(array, 'correlation'));
            })

            setData(corrData);
            // console.log('corrData', corrData);

        } catch (e) {
            console.log(e);
        }



    }


    const fetchData = async () => {
        try {

            
            if (!props.hasEnoughBalance) {
                return [];
            }
            setLoading(true);
            const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/correlation/'+props.source);

            if (response != null && response.results!=null 
                && response.results.length > 0 ) {

                let result: any = response;
                setResult(result.results);
                if(result.initResults!=null && result.initResults.length>0){
                    setChecked(result.initResults)
                }else{}
               
            
                setUncorrelatedList(response.unCorr);
                buildMatrix(result.results, result.initResults);

                setLoading(false);
            } else { }

        } catch (e) {
            console.log(e);
        }

    }

    const getToolTip = (x: number, y: number, value: number) => {
        try {


            return xLabels[x] + ' <> ' + yLabels[y] + ' ' + value;


        } catch (e) {
            console.log(e);
        }


    }

    const updateFilterFromSlider = (e: any) => {
        try {

            setFilter(Number(e.target.value));
            applyFilter(Number(e.target.value))
        } catch (e) {
            console.log(e);
        }

    }

    const resetFilter = (e: any) => {
        try {

            setFilter(0);
            applyFilter(0)
        } catch (e) {
            console.log(e);
        }

    }

    const updateHighlyCorrelatedFilter = (e: any) => {
        try {

            setFilter(0.8);
            applyFilter(0.8)
        } catch (e) {
            console.log(e);
        }

    }

    const updateHighlyUnCorrelatedFilter = (e: any) => {
        try {

            setFilter(-0.8);
            applyFilter(-0.8)
        } catch (e) {
            console.log(e);
        }

    }


    useEffect(() => {
        if(props.source==='ftx'){

            setTile('FTX PERPETUALS')
        }else{ }

        if(props.source==='binance'){

            setTile('BINANCE PERPETUALS')
        }else{ }

        if(props.source==='leaderboard'){

            setTile('$COPE Leaderboard Calls')
        }else{ }


        fetchData();

    }, [props.source])

    useEffect(() => {

        fetchData();

    }, [props.hasEnoughBalance])


    const zoomOut = () => {

        // setLabelFontSize('.65rem');
        // setCellHeightVal('4.5rem');

        setLabelFontSize('.02rem');
        setCellHeightVal('.5rem');
    }

    const zoomIn = () => {

        setLabelFontSize('.65rem');
        setCellHeightVal('4.5rem');

        // setLabelFontSize('.02rem');
        // setCellHeightVal('.5rem');
    }

    const handleSelectChange = (event: SelectChangeEvent<typeof symbolsFilter>) => {
        const {
            target: { value },
        } = event;

        console.log('value', value);
        const filter = typeof value === 'string' ? value.split(',') : value;
        setSymbolsFilter(
            // On autofill we get a the stringified value.
            filter
        );
        console.log('symbolsFilter', filter);
        buildMatrix(result, filter)
    };

    function getStyles(name: string, symbolsFilter: string[], theme: Theme) {
        return {
            fontWeight:
                symbolsFilter.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    function disableListItem(name: string) {

        return symbolsFilter?.indexOf(name) != -1;
    }

    const handleToggle = (value:any) => () => {
        const currentIndex:any = checked.indexOf(value);
        const newChecked:any = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        console.log(newChecked);
        setChecked(newChecked);
        setSymbolsFilter(
            // On autofill we get a the stringified value.
            newChecked
        );
        console.log('symbolsFilter', filter);
        buildMatrix(result, newChecked)
      };

      const handleToggleCorr = (value:any) => () => {

        const currentIndexSymbol1:any = checked.indexOf(value.symbol1);
        const currentIndexSymbol2:any = checked.indexOf(value.symbol2);

        const newChecked:any = [...checked];
    
        //If symbpl1 not in list and symbol 2 is in iist, add symbpl1
        if(currentIndexSymbol1 == -1 && currentIndexSymbol2 != -1){
            newChecked.push(value.symbol1);
        }else{}

        //If symbpl1 is in list and symbol 2 is not in iist, add symbpl2
        if(currentIndexSymbol1 != -1 && currentIndexSymbol2 == -1){
            newChecked.push(value.symbol2);
        }else{}

        //If symbpl1 and symbol 2 are not in the list, add both
        if(currentIndexSymbol1 == -1 && currentIndexSymbol2 == -1){
            newChecked.push(value.symbol2);
            newChecked.push(value.symbol1);
        }else{}


        //If symbpl1 and symbol 2 are in the list, remove both
        if(currentIndexSymbol1 != -1 && currentIndexSymbol2 != -1){
            newChecked.splice(currentIndexSymbol1, 1);
            newChecked.splice(currentIndexSymbol2, 1);
        }else{}


        // if (currentIndexSymbol1 === -1) {
        //   newChecked.push(value.symbol1);
        // } else {
        //   newChecked.splice(currentIndexSymbol1, 1);
        // }

        // if (currentIndexSymbol2 === -1) {
        //     newChecked.push(value.symbol2);
        // } else {
        //     newChecked.splice(currentIndexSymbol2, 1);
        // }

        console.log(newChecked);
        setChecked(newChecked);
        setSymbolsFilter(
            // On autofill we get a the stringified value.
            newChecked
        );
        console.log('symbolsFilter', filter);
        buildMatrix(result, newChecked)
      };



      interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
      }
      
      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    // console.log('DATA',data);
    return (
        <div>

            <Typography alignItems={'center'} variant={'h5'}>Correlation Matrix For {title} </Typography>
       
            {/* <p></p> */}

            {props.hasEnoughBalance ? <>

                {/* 
            <Typography alignItems={'center'}>0.9 to 1 (Bright Green) = Highly correlated. The markets move up and down in a very similar way</Typography>
            <Typography alignItems={'center'}> 0.5 to 0 (Darker Green) = Less correlated. The markets move up/down in a less similar way</Typography>
            <Typography alignItems={'center'}> -0 to -1 &lt;0&gt;0 (Red) = Negatively correlated. The markets move in opposite directions</Typography>
            <p></p>
            <Typography alignItems={'center'}>Hover over a cell to see market symbols </Typography>
            <p></p> */}


                {/* <div style={{width:400, paddingLeft:70   }}  >           
                     
                     <Slider
                         
                         aria-label="Temperature"
                         defaultValue={1}
                         // getAriaValueText={valuetext}
                         valueLabelDisplay="auto"
                         step={0.1}
                         marks
                         min={-1}
                         max={1}
                         onChange={updateFilterFromSlider}
                     /></div> */}
                <div>

                    {/* <FormControl sx={{ m: 1, width: 700 }}>
                        <InputLabel id="marketselector">Add/Remove Markets to Matrix</InputLabel>
                        <Select
                            labelId="marketselector"
                            id="marketselector"
                            multiple
                            value={symbolsFilter}

                            onChange={handleSelectChange}
                            input={<OutlinedInput label="Market" />}
                            MenuProps={MenuProps}
                        >
                            {labelsForSelect.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, symbolsFilter, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                </div>
                
                <Button onClick={zoomOut}>Zoom Out</Button>
                <Button onClick={zoomIn}>Zoom In</Button>
                {/* <Button onClick={updateHighlyCorrelatedFilter}> Show Highly Correlated</Button>
                <Button onClick={updateHighlyUnCorrelatedFilter}> Show Highly Uncorrelated</Button>
                <Button onClick={resetFilter}> Show All</Button> */}

                <Box sx={{ width: 500 }} >
                <CircularProgress hidden={!loading}  value={100}/>
                    <Grid container spacing={0.1}>

                        <Grid item xs={6} >

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Typography alignItems={'center'} >Add/Remove markets using the list below</Typography>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Uncorrelated" {...a11yProps(1)} hidden={uncorrelatedList==null || uncorrelatedList.length==0} />
                                </Tabs>
                            </Box>
                            {(labelsForSelect!=null && labelsForSelect.length>0) ?
                            <TabPanel value={value} index={0}>
                            <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
                                <List >

                                    {labelsForSelect.map((name) => (
                                        <ListItem key={name} >
                                            <ListItemButton id={name}  key={name}  role={undefined} onClick={handleToggle(name)} dense>
                                            <ListItemIcon>
                                                <Checkbox  id={name}  key={name} 
                                                    edge="start"
                                                    checked={checked.indexOf(name) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': name }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={name} primary={`${name}`} />
                                            </ListItemButton>
                                            </ListItem>

                                    ))}

                                </List>
                            </Paper>
                            </TabPanel>
                            :<></>}
                            {(uncorrelatedList!=null && uncorrelatedList.length>0)?
                            <TabPanel value={value} index={1}>
                           
                            <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
                               
                                <List >

                                    {uncorrelatedList.map((u) => (
                                        <ListItem key={u.symbol1+'_'+u.symbol2} >
                                            <ListItemButton role={undefined} onClick={handleToggleCorr(u)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(u.symbol1) !== -1 && checked.indexOf(u.symbol2)!=-1  }
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': u.symbol1+'_'+u.symbol2 }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={u.symbol1+'_'+u.symbol2} primary={`${u.symbol1+' '+u.symbol2+' '+u.correlation}`} />
                                            </ListItemButton>
                                            </ListItem>

                                    ))}

                                </List>
                                
                            </Paper>
                           
                            </TabPanel>
                             :<></>}

                        </Box>


                        </Grid>

                        <Grid item xs={6}>

                            <HeatMapGrid
                                data={data}
                                xLabels={xLabels}
                                yLabels={yLabels}
                                square={true}

                                // Reder cell with tooltip
                                cellRender={(x, y, value) => (
                                    //   <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
                                    <div title={getToolTip(x, y, value)}

                                        style={{ background: value >= 0.9 && x != y ? 'rgb(12, 160, 44,' + Math.abs(value) + ')' : (x == y) ? 'rgb(253, 112, 31)' : (value <= -0.6) ? 'rgb(255,0,17)' : 'rgb(169,169,169)' }}
                                    >{value}</div>
                                )}
                                xLabelsStyle={index => ({
                                    color: 'white',
                                    fontSize: labelFontSize,

                                })}
                                yLabelsStyle={() => ({
                                    fontSize: labelFontSize,
                                    textTransform: "uppercase",
                                    color: "white"
                                })}
                                cellStyle={(_x, _y, ratio) => {

                                    let bg;
                                    if (Number(ratio) < 0) {
                                        bg = `rgb(255,0,0, ${ratio})`;
                                    } else {
                                        bg = `rgb(12, 160, 44, ${ratio})`;
                                    }

                                    if (_x == _y) {

                                        bg = `rgb(253, 112, 31, ${ratio})`;

                                    } else { }


                                    return ({
                                        //background: bg,
                                        fontSize: labelFontSize,
                                        color: `rgb(255, 255, 255)`,
                                        fontWeight: 'bold'
                                    })
                                }

                                }
                                cellHeight={cellHeightVal}
                                xLabelsPos="top"
                            // onClick={(x, y) => alert(`Clicked (${x}, ${y})`)}
                            // yLabelsPos="right"
                            // square
                            />
                        </Grid>
                    </Grid>
                </Box>
            </> : <div>Connect wallet to view this page. You must also have 150 COPE or more to view this page. </div>
            }

        </div>


    )





}