import { Grid, Typography, Box } from "@mui/material";
import * as React from 'react';



export default function FAQ() {


    return (

            <div> 

                 <Typography>
                 
                </Typography>  
               

                <Typography align="left" paddingLeft="20">

                <h1>COPE Leaderboard FAQ </h1>
                <br></br>

                <h3> What is the COPE Leaderboard? </h3>

                <p> The COPE Leaderboard brings together the trade ideas from the best traders on Twitter.
                    <br></br>
                    It tracks these trade ideas and ranks their performance over time. 
                 </p>   

                    <h3> How does it help me? </h3>

                    <p> The COPE Leaderboard provides trade ideas and an overview of the sentiment of various tokens. </p>

                    <h3> How do I use the COPE Leaderboard </h3>

                    <p> The rankings show the historical performance of the trades by each of the traders.
                        <br></br>
                        Using a combination of the ranking and the frequency of a common trade idea in the dashboard, this should give you increased confidence in the trade idea.
                        <br></br>Use this process to find the best ideas to increase the probability of success in your trades. 
                    </p>

                    <h3> How much COPE do I need to access premium features? </h3>
                    <p> 
                        50 COPE is required to access: 
                        <ul>
                            <li>Advanced columns in the Portfolio view</li>
                            <li>Sentiment View</li>
                            <li>Charts View</li>
                        </ul>
                        90 COPE is required to access the Call analysis view. 
                        <br></br><br></br>
                        You just have to hold these COPE amounts in your wallet. They will not be spent.

                    </p>

                    <h3> What are the risks? </h3>
                    <p> 
                        The leaderboard is still in alpha and so the algorithm used to classify the trades is still being trained. There is a possibility that there are errors in the classification process, so please exercise caution. 
                    </p>

                    <h3> Disclaimer </h3>
                    <p> 
                    The content of the Leaderboard is not to be taken as financial advice. The purpose is to help surface trade ideas. What you do with this information is entirely up to you. 
                    </p>

                    <h3> Disclaimer </h3>
                    <p> 
                    The content of the Leaderboard is not to be taken as financial advice. The purpose is to help surface trade ideas. What you do with this information is entirely up to you. 
                    </p>

                    <h3> Your help is appreciated </h3>
                    <p> 
                        Given this is still an ALPHA release and our AI is still being trained on vasts mounts of data, we are likely to experience glitches from time to time. If you see any issue please DM @ninjagazden on Twitter and we will look to address ASAP.
                    </p>




                    </Typography>
                </div>

            )





}