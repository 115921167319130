import React from 'react';

import { Routes, Route } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import BarChart from '@mui/icons-material/BarChart';

import Functions from '@mui/icons-material/Functions';

import SentimentNeutral from '@mui/icons-material/SentimentNeutral';

import Calculate from '@mui/icons-material/Calculate';
import Person from '@mui/icons-material/Person';

import TableChart from '@mui/icons-material/TableChart';

import QuestionAnswer from '@mui/icons-material/QuestionAnswer';

import Grid3x3 from '@mui/icons-material/Grid3x3';

import WarningAmber from '@mui/icons-material/WarningAmber';
import PieChart from '@mui/icons-material/PieChart';
import TweetView from './components/TweetsView';
import AnalyticsView from './components/Analytics';
import PortfolioView from './components/Portfolio';
import SentimentView from './components/SentimentView';
import MarketDataView from './components/MarketDataView';
import { useEffect } from 'react';
import axios from 'axios';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import CallAnslysisView from './components/CallAnalysisView';
import FAQ from './components/FAQ';
import Profile from './components/Profile';
import CorrelationMatrix from './components/CorrelationMatrix';



const Tweets: React.FC = () => {
  return (
    <h1>Tweets</h1>
  );
};

const ClassifiedTweets: React.FC = () => {
  return (
    <h1>Classified Tweets</h1>
  );
};

const Metrics: React.FC = () => {
  return (
    <h1>Metrics</h1>
  );
};

const Sentiment: React.FC = () => {
  return (
    <h1>Market Sentiment</h1>
  );
};

export const paths = [
  {
    icon: <PieChart />,
    path: '/',
    title: 'Metrics'
  },
  {
    icon: <Functions />,
    path: '/call-analysis',
    title: 'Call analysis'
  },
  {
    icon: <TableChart />,
    path: '/portfolio-view',
    title: 'Portfolio'
  },

  {
    icon: <SentimentNeutral/>,
    path: '/sentiment',
    title: 'Sentiment'
  },{
    icon: <BarChart />,
    path: '/market-data',
    title: 'Charts'
  },
  {
    icon: <Person />,
    path: '/profile',
    title: 'API'
  },
  {
    icon: <QuestionAnswer />,
    path: '/faq',
    title: 'FAQ'
  },
  {
    icon: <img src='https://unlimitedcope.com/assets/img/favicon.svg' width='25' height='25' />,
    path: '/corrMatrix/leaderboard',
    title: 'Leaderboard Correlations',
  },
  {
    icon: <img src='https://logodix.com/logo/606388.png' width='30' height='30' />,
    path: '/corrMatrix/tradFiCrypto',
    title: 'TradeFi/Crypto Correlations',
  },
  {
    icon: <img src='https://help.ftx.com/hc/article_attachments/4410000944788/mceclip3.png' width='25' height='25' ></img>,
    path: '/corrMatrix/ftx',
    title: 'FTX Perps Correlations',
  },
  {
    icon: <img src='https://public.bnbstatic.com/20190405/eb2349c3-b2f8-4a93-a286-8f86a62ea9d8.png' width='25' height='25' ></img>,
    path: '/corrMatrix/binance',
    title: 'Binance Perps Correlations',
  }
  //
  //
  //  {
  //     icon: <CheckBoxCircle />,
  //     path:'/classifiedTweets',
  //     title: 'Classified Tweets'
  //  },
  //  {
  //     icon: <WarningAmber />,
  //     path:'/admin',
  //     title: 'Tweets'
  //  },


]


const RoutesDef: React.FC = () => {
  const [address, setAddress] = React.useState('');
  const { connection } = useConnection();
  const { publicKey, wallet, signMessage } = useWallet();
  const [copeBalance, setCopeBalance] = React.useState(0);
  const [hasEnoughBalance, setHasEnoughBalance] = React.useState(false);
  const [hasEnoughBalanceForCallAnalysis, setHasEnoughBalanceForCallAnalysis] = React.useState(false);
  const [hasEnoughBalanceForMatrix, setHasEnoughBalanceForMatrix] = React.useState(false);
  const [hasEnoughBalanceAPI, setHasEnoughBalanceForAPI] = React.useState(false);  


  const checkWalletIsConnected = async () => {

    console.log('wallet connected ', wallet?.adapter?.connected);
    if (wallet?.adapter?.connected) {
      await checkWalletBalanceState();

    } else {
      setCopeBalance(0);
      setHasEnoughBalance(false);
      setHasEnoughBalanceForCallAnalysis(false)

    }
  }




  const checkWalletBalanceState = async () => {
    try {

      if (publicKey != null) {

        const balance = await connection.getBalance(publicKey);
        const address = publicKey.toBase58();


        console.log('address', publicKey.toBase58());

        setAddress(publicKey.toBase58());

        let tokenMintAddress = '8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh';

        const response = await axios({
          url: `https://api.mainnet-beta.solana.com`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: [
            {
              jsonrpc: "2.0",
              id: 1,
              method: "getTokenAccountsByOwner",
              params: [
                publicKey.toBase58(),
                {
                  mint: tokenMintAddress,
                },
                {
                  encoding: "jsonParsed",
                },
              ],
            }
          ]
        });
        if (response.data != null && response.data.length > 0) {
          let result = response.data[0].result;
          if (result.value != null && result.value.length > 0) {

            let account = result.value[0].account;
            if (account != null
              && account.data != null
              && account.data.parsed != null
              && account.data.parsed.info != null
              && account.data.parsed.info.tokenAmount != null) {

              console.log('COPE Balance ', account.data.parsed.info.tokenAmount.uiAmount);
              await setCopeBalance(account.data.parsed.info.tokenAmount.uiAmount);
              console.log('COPE Balance state ', copeBalance);

            } else { }

          } else { }
        } else { }

      } else {
        console.log('public key not set');
      }


    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {

    if (copeBalance >= 50) {
      setHasEnoughBalance(true);
      console.log('enoughBalance?', hasEnoughBalance)
    } else { setHasEnoughBalance(false);}

    if(copeBalance>=90){
      setHasEnoughBalanceForCallAnalysis(true);
      console.log('enoughBalanceForCallAnalysis?', hasEnoughBalanceForCallAnalysis)

    }else{setHasEnoughBalanceForCallAnalysis(false);}

    if(copeBalance>=50){
      setHasEnoughBalanceForAPI(true);
      console.log('enoughBalanceForAPI?', hasEnoughBalanceAPI)
    }else{
      setHasEnoughBalanceForAPI(false);
    }

    if(copeBalance>=150){

      setHasEnoughBalanceForMatrix(true);
      console.log('enoughBalanceForMatrix?', hasEnoughBalanceForMatrix)

    }else{
      setHasEnoughBalanceForMatrix(false);
    }

  }, [copeBalance])

  useEffect(() => {

    checkWalletIsConnected()

  }, [wallet?.adapter.connected])

  useEffect(() => {

    checkWalletBalanceState();

  }, [publicKey])

  return (

    <Routes>
      {/* <Route path="/admin" element={<TweetView />} />
            <Route path="/classifiedTweets" element={<ClassifiedTweets />} /> */}
      <Route path="/market-data" element={<MarketDataView
        hasEnoughBalance={hasEnoughBalance}
        address={address}
        copeBalance={copeBalance}

      />} />
      <Route path="/sentiment" element={<SentimentView
        hasEnoughBalance={hasEnoughBalance}
        address={address}
        copeBalance={copeBalance}
      />} />
      <Route path="/portfolio-view"
        element={<PortfolioView
          hasEnoughBalance={hasEnoughBalance}
          address={address}
          copeBalance={copeBalance}
        />} />
      <Route path="/call-analysis"
        element={<CallAnslysisView
          hasEnoughBalance={hasEnoughBalanceForCallAnalysis}
          address={address}
          copeBalance={copeBalance}
        />} />
      <Route path="/" element={<AnalyticsView />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path='/corrMatrix/ftx' element={<CorrelationMatrix hasEnoughBalance={hasEnoughBalanceForMatrix} source={'ftx'}/>} />
      <Route path='/corrMatrix/binance' element={<CorrelationMatrix hasEnoughBalance={hasEnoughBalanceForMatrix} source={'binance'}/>} />
      <Route path='/corrMatrix/leaderboard' element={<CorrelationMatrix hasEnoughBalance={hasEnoughBalanceForMatrix} source={'leaderboard'}/>} />
      <Route path='/corrMatrix/tradFiCrypto' element={<CorrelationMatrix hasEnoughBalance={hasEnoughBalanceForMatrix} source={'tradFiCrypto'}/>} />
      <Route path="/profile" element={<Profile  hasEnoughBalance={hasEnoughBalanceAPI}  />} />
    </Routes>

  );
};



export default RoutesDef;