import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button } from '@mui/material';
import Iframe from 'react-iframe';

import TweetEmbed from 'react-tweet-embed'
import moment from 'moment';
// import ClassifyDialog from './ClassifyDialog';

import EventEmitter from "eventemitter3";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TraderCallCard(props: any) {

  const emitter = new EventEmitter();

  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  emitter.on('dialog', (m) => {
    setOpen(false);
  });

  const classify = () => {

    setOpen(true);
  }

  return (
    <Card sx={{ maxWidth: 345 }}>
      {/* <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} src={props.profileImage}>
            
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={'@'+props.username}
        subheader={moment(props.published).format('DD MMM YYYY HH:mm')}
      /> */}
      {/* <CardMedia
        component="img"
        height="194"
        image="https://pbs.twimg.com/profile_images/1460637330729885696/8vR22_iF_400x400.jpg"
        alt="Paella dish"
      /> */}

      <CardContent>
      <Typography variant="body2" color="text.tertiary">
            $COPE Leaderboard Call: {props.call}
        </Typography>
        <Typography variant="body2" color="text.tertiary">
            $COPE Leaderboard Rank: {props.rank}
        </Typography>
        <TweetEmbed id={props.id} options={{ cards: 'hidden', theme: 'dark', conversation: 'none' }} />

        {/* <Typography variant="body2" color="text.tertiary">
            {(props.cashTags!=null && props.cashTags!='')?'Symbols found: ':''}
        </Typography>

       <Typography variant="body1" color="text.primary">
            {props.cashTags!=null?props.cashTags.split(' ').map((tag:any)=>{
                return tag+ ' '
            }):''}
        </Typography> */}

        {/* <Typography variant="body2" color="text.secondary">
            {props.tweetText}
        </Typography> */}

      </CardContent>
      <CardActions disableSpacing>
        {/* <Button  onClick={classify} >Classify</Button>
        <Button>Ignore</Button> */}
        {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
          
        </ExpandMore> */}
      </CardActions>
      {/* <ClassifyDialog open={open} emitter={emitter} tweetId={props.id} /> */}
    </Card>

  );
}