import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import PortfolioTable from "./PortfolioTable";
import WidgetStats from "./WidgetStats";
import moment from 'moment';


interface PortfolioViewProps{
    hasEnoughBalance: boolean,
    address: string,
    copeBalance: number
}

export default function PortfolioView(props:PortfolioViewProps) {

    const [watchList, setWatchlist] = React.useState([]);
    const [originalValue, setOriginalValue] = React.useState(0);
    const [currentValue, setCurrentValue] = React.useState(0);
    const [roiPct, setRoiPct] = React.useState(0);
    const [tradeCount, setTradeCount] = React.useState(0);
    const [totalPnL, setTotalPnL]= React.useState(0);
    const [trades, setTrades] = React.useState([]);
    const [rois, setRois] = React.useState([]);
    const [lastRefreshTime, setLastRefreshTime] = React.useState([]);
    const [marketCapsAndRois, setMarketCapsAndRois] = React.useState([]);
    
    const fetchData = async ()=>{
        const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/portfolio');
        setWatchlist(response.watchList);

        response.watchList.map((i:any)=>{
            console.log(i.symbol, i.id);
        });

        setOriginalValue(response.totalInitalValue);
        setCurrentValue(response.totalCurrentValue);
        setRoiPct(response.totalROIpct);

        if(response.trades!=null){
            let sellTradeCount = 0;

            response.trades.map((t:any)=>{
                if(t.side==='SELL'){
                    sellTradeCount = sellTradeCount + 1;
                }else{}
            })

            setTradeCount(sellTradeCount);
        }else{}

        
        setTrades(response.trades);
        setRois(response.roiPcts);
        setLastRefreshTime(response.refreshTime);
        setMarketCapsAndRois(response.marketCapsAndRois);
        setTotalPnL(response.totalPnL);

        console.log(response);
        
    }


    useEffect( ()=>{

        fetchData();

    }, []) 
    
    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 60000);
        return () => clearInterval(interval);
      }, []);

    return (

        <div>
  
            <WidgetStats startingValue={originalValue} 
                        currentValue={currentValue} 
                        roiPct={roiPct} 
                        tradeCount={tradeCount}
                        totalPnL={totalPnL}/>
            <Typography>
                
                Last Updated: {moment(lastRefreshTime).format('DD MMM YYYY HH:mm:ss')} | COPE Balance: {props.copeBalance}   
            </Typography>            
            <PortfolioTable watchList={watchList} 
                            trades={trades} 
                            rois={rois} 
                            marketCapsAndRois={marketCapsAndRois}
                            totalPnL={totalPnL}
                            hasEnoughBalance={props.hasEnoughBalance}
                            address={props.address}
                            balance={props.copeBalance}
                            />

            
        </div>


    );


}