import * as React from 'react';
import { DataGrid, GridColDef, GridSortModel, GridValueGetterParams, GridColumns } from '@mui/x-data-grid';

import { default as NumberFormat } from 'react-number-format';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PropTypes from "prop-types";
import moment from 'moment';
import {
    CRow,
    CCol,
    CWidgetStatsA,
  } from '@coreui/react'

import { useState, useEffect, useRef } from 'react';

import { AgChartsReact } from 'ag-charts-react';

import { Sparklines, SparklinesLine } from 'react-sparklines';

import { watch } from 'fs';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CSVLink, CSVDownload } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import axios from 'axios';
import TreeMapMarketCap from './TreeMapMarketCaps';
import AlertDialog from './AlertDialog';



interface PortfolioProps{
    watchList:any,
    trades:any,
    rois:any,
    marketCapsAndRois:any,
    totalPnL:any
    hasEnoughBalance:boolean,
    address:string,
    balance:number
  }
  


export default function PortfolioTable(props:PortfolioProps) {

    const [value, setValue] = React.useState(0);
    const [sellMode, setSellMode] = React.useState('');
    const [showAlert, setShowAlert] = React.useState(false);


    
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'roipct',
          sort: 'desc',
        },
      ]);

      const postSellSymbol = async () =>{
        try{

            if(sellMode){

                setShowAlert(true);
                const { data: response } = await axios.post(process.env.REACT_APP_PROXY+'sell/'+sellMode+'/876678/'+props.address+'/'+props.balance);
            }else{}
            

        }catch(e){
            console.log(e);
        }
        
      }

      useEffect( ()=>{

        window.addEventListener('CLOSE', ()=>{
            setShowAlert(false);
        })

      },[])

      
      useEffect( ()=>{

        postSellSymbol();

       }, [sellMode]);
       


      const sellSymbol = (symbol:string) =>{
        setSellMode(symbol);
        console.log(symbol);

      }

      const [tradeSortModel, setTradeSortModel] = React.useState<GridSortModel>([
        {
          field: 'timestamp',
          sort: 'desc',
        },
      ]);


    const tradeTableColumns: GridColumns = [
        { field: 'timestamp', headerName: '', width: 170, 
            valueFormatter: (params:any) => {
            let valueFormatted = moment(params.value).format('DD MMM YYYY HH:mm:ss');
            return `${valueFormatted}`;
            },} ,
        { field: 'symbol', headerName: '', width: 100 },
        { field: 'side', headerName: 'Side', width: 100 },
        { field: 'amount', headerName: 'Qty', width: 100,
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value}
                                 thousandSeparator={true}
                                 displayType={'text'}
                                 decimalScale={3}
                                 />;
            
            }
        },
        { field: 'price', headerName: 'Price', width: 100,
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value} prefix={'$'}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={8}
                                />;
            }
        },
        { field: 'value', headerName: 'Value', width: 100,
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value}
                                 thousandSeparator={true}
                                 prefix={'$'}
                                 displayType={'text'}
                                 decimalScale={0}
                                 />;
            
            }
        },
        { field: 'upside', headerName: 'Gain/Loss', width: 100,
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value}
                                 thousandSeparator={true}
                                 prefix={'$'}
                                 displayType={'text'}
                                 decimalScale={2}
                                 />;
            
            }
        },
        { field: 'roiPct', headerName: 'ROI', width: 100,
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value}
                                 thousandSeparator={true}
                                 suffix={'%'}
                                 displayType={'text'}
                                 decimalScale={2}
                                 />;
            
            }
        },
        { field: 'side', headerName: 'Position', width: 80,         
        renderCell: (params:any)=>  {     
               
            if(params.value!=null){

                return params.value==='BUY'?'OPEN':'CLOSED';

            }else{
                return '';
            }

            
            } },
        { field: 'buyers', headerName: 'Buyers', width: 100,         
        renderCell: (params:any)=>  {     
               
            if(params.value!=null && params.value.length>0){

                return <Tooltip title={params.value[0]}><div>{params.value[0]}</div></Tooltip>

            }else{
                return '';
            }

            
            } },
            
            { field: 'sellerAddress', headerName: 'Seller Address', width: 160,         
            renderCell: (params:any)=>  {     
                   
                if(params.value!=null && params.value.length>0){
    
                    return <Tooltip title={params.value}><div>{params.value}</div></Tooltip>
    
                }else{
                    return '';
                }
    
                
                } },
                { field: 'copeBalance', headerName: 'COPE QTY', width: 100,
                renderCell: (params:any)=>  {            
                    return <NumberFormat value={params.value}
                                         thousandSeparator={true}
                                         displayType={'text'}
                                         decimalScale={2}
                                         />;
                    
                    } },

    ]


    const columns: GridColumns = [
        { field: 'image', headerName: '', width: 30, renderCell: (params:any)=> 
            <div style={{backgroundColor:'#282828'}}>
                <img src={params.value} width='30' height='30' />
            </div>
            
        },
        { field: 'name', headerName: '', width: 120, 
            renderCell: (params:any) => {
                return <Tooltip title={params.value}><Typography noWrap>{params.value}</Typography></Tooltip>
            }
        },
        { field: 'symbol', headerName: '', width: 100},
        { field: 'roipct', headerName: 'ROI', width: 80,     
        renderCell: (params:any)=>  {            
            return <div style={{color:params.value<0?'red':'green', fontStyle:'bold'}}>
                        <NumberFormat value={params.value} suffix={'%'}
                                displayType={'text'}
                                decimalScale={2}
                                />
                  </div>;
            }
        },
        { field: 'boughtAt', headerName: 'EntryPx', width: 95,     
                renderCell: (params:any)=>  {            
                return <NumberFormat value={params.value} prefix={'$'} displayType={'text'} decimalScale={8} 
                thousandSeparator={true} />;
                }
        },
        { field: 'price', headerName: 'CurrentPx', width: 95,     
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value} prefix={'$'}
                                displayType={'text'}
                                decimalScale={8}
                                thousandSeparator={true} 
                                />;
            }
        },
        { field: 'amount', headerName: 'Qty', width: 70,     
        renderCell: (params:any)=>  {            
        return <NumberFormat value={params.value} 
                             thousandSeparator={true}
                             displayType={'text'}
                             decimalScale={3}
                             />;
      }
     }, 
     { field: 'currentValue', headerName: 'Value', width: 85, hide: !props.hasEnoughBalance,    
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value} prefix={'$'}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                />;
            }
        },
        { field: 'sparkLine', headerName: 'Price 7D', width: 90, hide: !props.hasEnoughBalance,    
        renderCell: (params:any)=>  {            
            return <Sparklines data={params.value} svgWidth={90} svgHeight={20}> 
                        <SparklinesLine color="cyan"/> 
                    </Sparklines>
            }
        },
        { field: 'totalVolume', headerName: 'Volume', width: 120, hide: !props.hasEnoughBalance,    
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value} prefix={'$'}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={0}
                                />;
            }
        },
        { field: 'id', headerName: '', width: 80, renderCell: (params:any)=>  {            
            return <Button variant="outlined" disabled={!props.hasEnoughBalance}  onClick={() => {
                sellSymbol(params.value);
              }}>  SELL</Button>;
            }
        },
        { field: 'marketCap', headerName: 'MktCap', hide: !props.hasEnoughBalance,
          headerClassName: 'super-app-theme--header', width: 120,     
        renderCell: (params:any)=>  {            
            return <NumberFormat value={params.value} prefix={'$'}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={0}
                                />;
            }
        },
        { field: 'marketCapChg24pct', headerName: '24H%Chg', headerClassName: 'super-app-theme--header', width: 100, hide: !props.hasEnoughBalance,    
        renderCell: (params:any)=>  {            
            return <div style={{color:params.value<0?'red':'green'}}>
                        <NumberFormat value={params.value} suffix={'%'}
                                displayType={'text'}
                                decimalScale={2}
                                />
                  </div>;
            }
        },


    ]

    const roiOptions =  {
   
        legend: {
          position: 'bottom', // 'bottom', 'left', 'top'
          enabled: false
        },
        autoSize: true,
        width: '100%',
        height: '100%',
        theme: 'ag-vivid-dark',
        data: props.rois,
        series: [
          {
            type: 'line',
            yKey: 'roiPct',
            xKey: 'time',
            fills : ['#266EF6']
          },
        ],
        axes: [
            {
              type: 'category',
              position: 'bottom',
              visible: false,
              label: {
                formatter: function (params:any) {
                  return '';
                }
              }
            },
            
            {
              type: 'number',
              position: 'left',
              ticks:10
            }
          ],
        
        padding: {
          bottom: 30 // default: 20
  
        }
      };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    const DataTable = () => {
        return (
          <div style={{ height: '100%', width: '100%'}}>
            <DataGrid
             
              rows={props.watchList}
              columns={columns}
              rowsPerPageOptions={[25]}
              hideFooterPagination={true}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              sortingOrder={['asc','desc']}
            />
          </div>
        );
      };

      const TradesDataTable = () => {
        return (
          <div style={{ height: '100%', width: '100%'}}>
            <DataGrid
             
              rows={props.trades}
              columns={tradeTableColumns}
              rowsPerPageOptions={[25]}
              hideFooterPagination={true}
              sortModel={tradeSortModel}
              onSortModelChange={(model) => setTradeSortModel(model)}
              sortingOrder={['asc','desc']}
            />
          </div>
        );
      };

      const TabPanel = (props:any) => {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
          >
            <Box p={3}>{children}</Box>
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
      };
      
      function a11yProps(index:any) {
        return {
          id: `scrollable-auto-tab-${index}`,
          "aria-controls": `scrollable-auto-tabpanel-${index}`
        };
      }


    return (
        
        <CRow>
            <CCol>
            <Tabs 
                      indicatorColor="secondary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      onChange={handleChange}
                      value={value}
             >
                    <Tab label="Portfolio" {...a11yProps(0)} defaultChecked/>
                    <Tab label="Trades" {...a11yProps(1)} />
                    <Tab label="ROI % History" {...a11yProps(2)} />
                    <Tab label="Analysis" {...a11yProps(3)} />
            </Tabs> 
            <TabPanel value={value} index={0}>
            <Typography>This portfolio shows alt coin holdings of a trading algo that analyses BUY/SELL signals originating from the COPE Leaderboard. The Trade tab shows all BUY and SELL trades. Hover over the information icon for more detail.  </Typography> 
             <Box sx={{ 
                        p: -0.9,
                        m: -0.9,
                        display: 'flex',
                        flexDirection: 'row-reverse'
                        }}> 
                    <Tooltip title="This simulated portfolio consists of alt coins that meet the following Entry Criteria: 
                    1.The coin has 2 or more Buy calls in the COPE Leaderboard. 
                    2.The Buy calls constitute >=70% of all calls for the coin.
                    3.If a coins call status changes from BUY to SELL, any other status or constitutes <70% of all calls for the coin, the coin is sold and removed from the portfolio.
                    In addition:  
                    A.$10,000 of each coin are purchased. 
                    B.The purchase price (Invested@) is stored and used to calculate an estimated ROI (return on investment) as the price ticks.
                    C.Upside/downside from the trade is factored into the ROI.">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>    
                    <CSVLink data={props.watchList}  filename={"portfolio-cope-metrics.csv"} style={{color:'white'}}>
                        <Tooltip title="Download csv data for this table">
                            <IconButton>
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </CSVLink> 
      
            </Box>   
            <Box sx={{
                        '& .super-app-theme--header': {
                        backgroundColor: '#282828',
                        },
                    }}
                >
                    <DataTable />
                </Box>
            </TabPanel> 
            <TabPanel value={value} index={1}>
                <Box sx={{ 
                            p: -0.9,
                            m: -0.9,
                            display: 'flex',
                            flexDirection: 'row-reverse'
                            }}> 
                        <Tooltip title="A BUY trade occurs when a new coin enters the portfolio after meeting the Entry Criteria. A SELL trade occurs if the current status of the coins call changes from BUY to another status or the number of BUY calls falls below 2 or falls below 70% of all calls for the relevant coin.">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>    
                        <CSVLink data={props.trades}  filename={"trades-cope-metrics.csv"} style={{color:'white'}}>
                            <Tooltip title="Download csv data for this table">
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </CSVLink>     
                </Box> 
                <TradesDataTable/>
            </TabPanel> 
            <TabPanel value={value} index={2}>
                <Box sx={{ 
                            p: -0.9,
                            m: -0.9,
                            display: 'flex',
                            flexDirection: 'row-reverse'
                            }}> 
                        <Tooltip title="ROI is recorded every minute.">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>    
                        <CSVLink data={props.rois}  filename={"rois-cope-metrics.csv"} style={{color:'white'}}>
                            <Tooltip title="Download csv data for this chart">
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </CSVLink>  
                </Box> 
                <Box sx={{ width: '100%', height:'100%', alignItems:'center'}}>
                <AgChartsReact options={roiOptions}  />
              </Box> 
            </TabPanel> 
            <TabPanel value={value} index={3}>
                <TreeMapMarketCap data={props.marketCapsAndRois}/>    
            </TabPanel> 
            
            <AlertDialog show={showAlert} symbol={sellMode}/>
            </CCol>

        </CRow>
      


    );


}
