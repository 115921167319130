import React from 'react'
import {
  CRow,
  CCol,
  CWidgetStatsA,
} from '@coreui/react'


import { default as NumberFormat } from 'react-number-format';

interface WidgetProps{
  startingValue:number,
  currentValue:number,
  roiPct:number,
  tradeCount:number,
  totalPnL:number
}


export default function WidgetStats(props:WidgetProps) {
  return (
    <CRow>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="primary"
          value={
            <>
            <NumberFormat value={props.startingValue} prefix={'$'}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    />
              {' '}

            </>
          }
          title="Allocated from $1,500,000"
          
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="info"
          value={
            <>
                  <NumberFormat value={props.currentValue} prefix={'$'}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={0}
                            />
              {' '}
            </>
          }
          title="Current Value"
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="warning"
          value={
            <>
            <NumberFormat value={props.roiPct} suffix={'%'}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={3}
            />
              {' '}

            </>
          }
          title="Estimated ROI"
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="secondary"
          value={
            <>
            <NumberFormat value={props.totalPnL}
              displayType={'text'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
            />{' '}

            </>
          }
          title={"Total PnL from " + props.tradeCount +' trade(s)'} 
        />
      </CCol>

    </CRow>
  )
}

