import { Grid, Typography, Box, Input, Button } from "@mui/material";
import * as React from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { sign } from 'tweetnacl';
import bs58 from 'bs58';
import { Label } from "recharts";
import ReactJson from 'react-json-view'
import * as sampleMetrics from './sampleMetrics.json';
import * as sampleAnalysis from './sampleAnalysis.json';
import * as sampleAlgoData from './sampleAlgoData.json';

interface CallAnalysisiViewProps {
    hasEnoughBalance: boolean,

}

export default function Profile(props:CallAnalysisiViewProps) {
    const { publicKey, wallet, signMessage } = useWallet();
    const [sig, setSig] = React.useState('');
    const [pubKey, setPubKey] = React.useState('');

    const genSignature = async () => {
        try{
    
          // const salt = await bcrypt.genSalt(6);
          // const hashed = await bcrypt.hash(address, salt);
          const message = new TextEncoder().encode('I am signing this message to verify it is indeed me.');
          // Sign the bytes using the wallet
          if (signMessage && publicKey){
            const signature = await signMessage(message);
            if (sign.detached.verify(message, signature, publicKey.toBytes())){
    
              setSig(bs58.encode(signature));
              setPubKey(bs58.encode(publicKey.toBytes()));
            }else{
              alert('Your wallet is not connected or your wallet does not support signing.')
            }
          
          }else{
            alert('Your wallet is not connected or your wallet does not support signing.')
          }
    
        }catch(e){
          console.log(e);
        }
    
      }

    return (

        props.hasEnoughBalance?
            <div dir='ltr'>
            <Box>
                
                <Grid container direction={'column'} alignItems={'start'} gap={2}> 
                    <Grid item >
                    <Typography align="left"  variant="h5">Congratulations! you can now access the COPE API</Typography>
                    </Grid>
                    <Grid item >
                     <Button  variant='outlined' onClick={genSignature} > Click here to Generate API Token</Button>
                    </Grid>
                    <Grid item>
                        <Typography align="left"  color="lightsalmon">
                        You will be asked to sign a message to verify that this is your wallet. You cannot proceed without this verification.
                        </Typography>
                   
                    </Grid>
                    <Grid item >
                        <Typography align="left"  >Your API Key: <Typography color="lightseagreen" style={{ fontWeight: 600 }}  >{sig}</Typography></Typography>
                    </Grid>
                    <Grid item >
                        <Typography align="left" >Your Public Key (Verified Solana Wallet Address): <Typography style={{ fontWeight: 600 }} color="lightseagreen" >  {pubKey} </Typography>  </Typography>
                    </Grid>
                    <Grid item>
                    <Typography align="left"  >Once loaded, copy these keys to a safe location. You can always generate the keys again.</Typography>
                    </Grid>
                </Grid>

                <Grid container direction={'column'} alignItems={'start'} gap={2}> 
                    <Grid item >
                
                    </Grid> 
                    <Grid item >
                    <Typography align="left" variant="h5">How to use the API</Typography>
                    </Grid>
                    <Grid item >
                        <Typography align="left" >You can make simple GET requests to the following endpoints. Click on arrows to expand sample result:</Typography>
                    </Grid>
                    <Grid item >
                        <Typography align="left"  variant="h6" color="lightseagreen">https://metrics2.unlimitedcope.com/api/algo-data/API_KEY/PUBLIC_KEY</Typography>
                        <Typography align="left"  color="lightskyblue" >This call will give you back JSON data used by the Portfolio section. </Typography>
                        <Typography  align="left" ><ReactJson src={{...sampleAlgoData}} theme="summerfruit" collapsed={true}  ></ReactJson></Typography>
                      
                    </Grid>
                    <Grid item >
                        <Typography align="left"  variant="h6" color="lightseagreen">https://metrics2.unlimitedcope.com/api/metrics/API_KEY/PUBLIC_KEY</Typography>
                        <Typography align="left"  color="lightskyblue" >This call will give you back JSON data used by the Metrics section. </Typography>
                        <Typography  align="left" ><ReactJson src={{...sampleMetrics}} theme="summerfruit" collapsed={true}  ></ReactJson></Typography>
                    </Grid>
                    <Grid item >
                        <Typography align="left"  variant="h6" color="lightseagreen">https://metrics2.unlimitedcope.com/api/analysis/API_KEY/PUBLIC_KEY/BASE/QUOTE</Typography>
                        <Typography align="left"  color="lightskyblue" >This call will give you back data used by the Call Analysis section. You have to specify a Base and Quote to tell the system for which market you would like the analysis. The result consists of a timeseries of OHLCV data. Each OHLCV tuple can contain one or more tweets based on when they were tweeted </Typography>
                        <Typography  align="left" ><ReactJson src={{...sampleAnalysis}} theme="summerfruit" collapsed={true}></ReactJson></Typography>
                    </Grid>
                    <Grid item >
                        <Typography align="left"  variant="h6" color="lightseagreen">https://metrics2.unlimitedcope.com/api/analysis-symbols/API_KEY/PUBLIC_KEY</Typography>
                        <Typography align="left"  color="lightskyblue" >This call will give you back a list of market symbols, one of which can be supplied to the analysis request above. </Typography>
                        
                    </Grid>


                </Grid>


            </Box>
            </div>
            :<div>You must hold 200 COPE to access your API key</div> 


            )





}