import { Grid, Typography } from "@mui/material";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MiniChart } from "react-ts-tradingview-widgets";

interface MarketDataViewProps{
    hasEnoughBalance: boolean,
    address: string,
    copeBalance: number
}

export default function MarketDataView(props:MarketDataViewProps) {
    //portfolio-symbols
    const [watchList, setWatchlist] = React.useState([]);



    const fetchData = async () => {

        if (props.hasEnoughBalance) {

            const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/portfolio-symbols');

            if (response != null) {

                const modResp: any = new Array();

                response.map((w: string) => {
                    let ticker = w.replace('/', '');
                    modResp.push(ticker);
                })

                setWatchlist(modResp);



            } else { }
        } else { }

    }

    useEffect(() => {

        fetchData();

    }, [props.hasEnoughBalance]);

    return (

        <div>

            <Typography>
                Charts for Portfolio Assets
            </Typography>

            <Typography>
                &nbsp;
            </Typography>

            <Grid container gap={2}>


                {watchList.map((w: string) => {

                    return <Grid item>
                        <MiniChart symbol={w} colorTheme="dark"></MiniChart>
                    </Grid>
                })

                }
                {!props.hasEnoughBalance ? <div>Connect wallet to view this page. You must also have 50 COPE or more to view this page. Your balance is : {props.copeBalance}</div> : <div></div>}

            </Grid>
        </div>

    )





}