import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Grid, Container, Autocomplete, TextField, Typography, 
    AppBar, Button, Card, CardContent, CardHeader, Tooltip 

} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';

import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from '@mui/icons-material/Share';

import { CSVLink, CSVDownload } from 'react-csv';

import MoreVertIcon from '@mui/icons-material/MoreVert';




const _ = require('lodash');

const useStyles = makeStyles({
    root: {
      pieContainer2: {
        width: '100%',
        height: '100%'
      },
      pieContainer1: {
        width: '800px',
        height: '800px'
      }
        //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //   border: 0,
        //   borderRadius: 3,
        //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        //   color: 'white',
        //   height: 48,
        //   padding: '0 30px',
    },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AnalyticsView() {

    const classes = useStyles();

    const style = {

      card: {
        header: {
          padding: 5,
          height: 70
        }
      }
    };

    const [bTCCounts, setBTCCounts] = React.useState([]);
    const [eTHCounts, setETHCounts] = React.useState([]);
    const [allCallCounts, setAllCallCounts] = React.useState([]);
    const [altCoinCounts, setAltCoinCounts] = React.useState([]);
    const [callsPerSymbol, setAllCallsPerSymbol] = React.useState([]);


    const fetchAnalytics = async () => {

        const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/leaderboard-metrics');
        
        setBTCCounts(response.btcCallCounts==null?[]:response.btcCallCounts);
        setETHCounts(response.ethCallCounts==null?[]:response.ethCallCounts);
        setAllCallCounts(response.allCallCounts==null?[]:response.allCallCounts);
        setAltCoinCounts(response.altCointCallCounts==null?[]:response.altCointCallCounts);
        setAllCallsPerSymbol(response.callsPerSymbol==null?[]:response.callsPerSymbol);

        console.log('btcCounts', response.btcCallCounts);

    }

    useEffect( ()=>{

        fetchAnalytics();

    }, [])

    const chartThemes =  ['ag-pastel', 'ag-material-dark', 'ag-vivid-dark', 'ag-solar'];
    
    const width = 200;
    const height = 300;

    const btcOptions =  {
   
      legend: {
        position: 'bottom', // 'bottom', 'left', 'top'
        enabled: false 
      },
      autoSize: true,
      width: width,
      height: height,
      theme: 'ag-vivid-dark',
      data: bTCCounts,
      series: [
        {
          type: 'pie',
          angleKey: 'count',
          labelKey: 'call',
          fills : ['#266EF6','#C60404','#35B535','#FF8B00','#C1D82F','#BF00FF'],
          callout: {
            length: 15, // default: 10
            colors: ['#266EF6','#C60404','#35B535','#FF8B00','#C1D82F','#BF00FF']
          }
        },
      ],
      padding: {
        bottom: 30 // default: 20

      }
    };

    const ethOptions =  {
   
      legend: {
        position: 'bottom', // 'bottom', 'left', 'top'
        enabled: false 
      },
      autoSize: true,
      width: width,
      height: height,
      theme: 'ag-vivid-dark',
      data: eTHCounts,
      series: [
        {
          type: 'pie',
          angleKey: 'count',
          labelKey: 'call',
          fills : ['#266EF6','#C60404','#35B535','#FF8B00','#C1D82F','#BF00FF'],
          callout: {
            length: 10, // default: 10
            colors: ['#266EF6','#C60404','#35B535','#FF8B00','#C1D82F','#BF00FF']
          }
        },
      ],
      padding: {
        bottom: 30 // default: 20

      }
    };


    const allCallOptions =  {
   
      legend: {
        position: 'bottom', // 'bottom', 'left', 'top'
        enabled: true
      },
      autoSize: true,
      width: width,
      height: height+350,
      theme: 'ag-vivid-dark',
      data: allCallCounts,
      series: [
        {
          type: 'column',
          yKeys: ['BUY', 'SELL',  'Waiting for Lower','Waiting for Higher', 'Trade Completed','Trade Invalidated'],
          xKey: 'call',
          fills : ['#266EF6','#C60404','#FF8B00','#35B535','#C1D82F','#BF00FF']
        },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          visible: false,
          label: {
            formatter: function (params:any) {
              return '';
            }
          }
        },
        
        {
          type: 'number',
          position: 'left',
          ticks:10
        }
      ],
      
      padding: {
        bottom: 30 // default: 20

      }
    };

    const altCoinsCountOptions =  {
   
      legend: {
        position: 'bottom', // 'bottom', 'left', 'top'
        enabled: true 
      },
      autoSize: true,
      width: width,
      height: height+350,
      theme: 'ag-vivid-dark',
      data: callsPerSymbol,
      series: [
        {
          type: 'bar',
          xKey: 'Symbol',
          yKeys: ['BUY', 'SELL', 'Waiting_for_Lower', 'Waiting_for_Higher', 'Trade_Completed', 'Trade_Invalidated' ],
          yNames: ['BUY', 'SELL', 'Waiting for Lower', 'Waiting for Higher', 'Trade Completed', 'Trade Invalidated' ],
          strokes: ['#266EF6','#C60404','#FF8B00','#35B535','#C1D82F','#BF00FF'],
          fills : ['#266EF6','#C60404','#FF8B00','#35B535','#C1D82F','#BF00FF'],
        },
      ]
      // data: altCoinCounts,
      // series: [
      //   {
      //     type: 'bar',
      //     yKeys: ['count'],
      //     xKey: 'symbol',
      //     fills : ['#C1D82F'],
      //   }
      // ]

    };

    /**
      BUY: 1,
      SELL: 0,
      Waiting_for_Lower: 0,
      Waiting_for_Higher: 0,
      Trade_Completed: 0,
      Trade_Invalidated: 0,
      totalCalls: 1

     */
      // var callsPerSymbolOptions = {
 
      //   // title: {
      //   //   text: "Apple's revenue by product category",
      //   // },
      //   // subtitle: {
      //   //   text: 'in billion U.S. dollars',
      //   // },
      //   data: callsPerSymbol,
      //   series: [
      //     {
      //       type: 'bar',
      //       xKey: 'symbol',
      //       yKeys: ['BUY', 'SELL', 'Waiting_for_Lower', 'Waiting_for_Higher', 'Trade_Completed', 'Trade_Invalidated' ],
      //       yNames: ['BUY', 'SELL', 'Waiting for Lower', 'Waiting for Higher', 'Trade Completed', 'Trade Invalidated' ],
      //     },
      //   ],
      // };


    return (

      <Box sx={{ width: '100%'}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        alignItems="center"
        justifyContent="center">
        <Grid item  md={6}>
          <Card >
          <CardHeader
              titleTypographyProps={{variant:'h6' }}
              style={style.card.header}
              action={
                <IconButton aria-label="settings">
                  <Box         
                      sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                  <Tooltip title="This chart aggregates BTC/USD calls across all participants on the $COPE Leaderboard">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                  </Tooltip>    
                 <CSVLink data={bTCCounts}  filename={"btc-usd-call-counts.csv"} style={{color:'white'}}>
                    <Tooltip title="Download csv data for this chart">
                        <IconButton>
                            <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                 </CSVLink>
                 </Box>
                </IconButton>
              }
              title="$COPE Leaderboard Crypto Twitter Call Maker Sentiment For BTC/USD"
            />
            <CardContent>
              <Box sx={{ width: '100%', height:'100%', alignItems:'center'}}>
                <AgChartsReact options={btcOptions}  />
              </Box> 
            </CardContent> 
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
          <CardHeader
              titleTypographyProps={{variant:'h6' }}
              style={style.card.header}
              action={
                <IconButton aria-label="settings">
                  <Box         
                      sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Tooltip title="This chart aggregates ETH/USD calls across all participants on the $COPE Leaderboard">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                  </Tooltip>    

                    <CSVLink data={eTHCounts}  filename={"eth-usd-call-counts.csv"} style={{color:'white'}}>
                      <Tooltip title="Download csv data for this chart">
                          <IconButton>
                              <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                    </CSVLink>

                 </Box>
                </IconButton>
              }
              title="$COPE Leaderboard Crypto Twitter Call Maker Sentiment For ETH/USD"
            />
          <CardContent>
            <Box sx={{ width: '100%', height:'100%', alignItems:'center'}}>

              <AgChartsReact options={ethOptions}  />

            </Box> 
           </CardContent>
          </Card>
        </Grid>
        <Grid item  md={6}>
          <Card>
          <CardHeader
              titleTypographyProps={{variant:'h6' }}
              style={style.card.header}
              action={
                <IconButton aria-label="settings">
                  <Box         
                      sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>

                  <Tooltip title="This chart shows total number of calls per category across all participants on the $COPE Leaderboard">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                  </Tooltip>      
                  

                  <CSVLink data={allCallCounts}  filename={"all-call-counts.csv"} style={{color:'white'}}>
                      <Tooltip title="Download csv data for this chart">
                          <IconButton>
                              <DownloadIcon />
                          </IconButton>
                      </Tooltip>
                  </CSVLink>

                 </Box>
                </IconButton>
              }
              title="$COPE Leaderboard Call Maker's Current Status's"
            />
            <CardContent>
                <Box sx={{ width: '100%', height:'100%', alignItems:'center'}}>
                  <AgChartsReact options={allCallOptions}  />
                </Box> 
             </CardContent> 

          </Card>
        </Grid>
          <Grid item  md={6}>
            <Card>
          <CardHeader
              titleTypographyProps={{variant:'h6' }}
              style={style.card.header}
              action={
                <IconButton aria-label="settings">

                  <Box         
                      sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>

                  <Tooltip title="This chart shows total number of calls per alt coin across all participants on the $COPE Leaderboard. The x-axis represents the count">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                  </Tooltip>        

                 <CSVLink data={callsPerSymbol}  filename={"alt-call-counts.csv"} style={{color:'white'}}>
                      <Tooltip title="Download csv data for this chart">
                          <IconButton>
                              <DownloadIcon />
                          </IconButton>
                      </Tooltip>
                 </CSVLink>

                 </Box>
                </IconButton>
              }
              title="$COPE Leaderboard Alts Call Counts"
            />
          <CardContent>
            <Box sx={{ width: '100%', height:'100%', alignItems:'center'}}>
              <AgChartsReact options={altCoinsCountOptions}  />
            </Box> 
           </CardContent>  
          </Card>
        </Grid>    
      </Grid>
    </Box>


    )

}