import { Box, Grid, Typography, Autocomplete, TextField, Button } from "@mui/material";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { values } from "lodash";
import TweetEmbed from 'react-tweet-embed'
import Masonry from 'react-masonry-css';
import moment from "moment";
import _ from 'lodash';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush } from 'recharts';
import TraderCallCard from "./TradeCallCard";

const BB = require('technicalindicators').BollingerBands
const EMA = require('technicalindicators').EMA
const SMA = require('technicalindicators').SMA
const VWAP = require('technicalindicators').VWAP
const period = 14;

interface CallAnalysisiViewProps {
    hasEnoughBalance: boolean,
    address: string,
    copeBalance: number
}

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
};




const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, payload, value } = props;

    if (payload != null && payload.tweets != null && payload.tweets.length > 0) {
        return (
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
            </svg>
        );
    }

    return (
        <div></div>
    );
};




export default function CallAnslysisView(props: CallAnalysisiViewProps) {
    //portfolio-symbols
    const [ohlcv, setOHLCV] = React.useState([]);
    const [closePrices, setClosePrices] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [tweets, setTweets] = React.useState([]);
    const [lineData, setLineData] = React.useState([]);
    const [calls, setCalls] = React.useState([]);
    const [symbols, setSymbols] = React.useState(['BTC/USD']);
    const [symbolSelected, setSymbolSelected] = React.useState('BTC/USD');

    const [techIndicators, setTechindicators] = React.useState(['']);
    const [selectedTI, setSelectedTI] = React.useState('Select');

    const [zoomIn, setZoomIn] = React.useState(false);
    const [bb, setBB] = React.useState(false);

    const [ema, setEMA] = React.useState(false);
    const [sma, setSMA] = React.useState(false);

    const [vwap, setVWAP] = React.useState(false);

    const [domainStart, setDomainStart] = React.useState(0);
    const [domainEnd, setDomainEnd] = React.useState(0);


    const techIndicatorValues = [
        'Bollinger Bands',
        'EMA20',
        'SMA20',
        'None'

    ]

    
    const CustomTooltip = ({ active, payload, label }: any) => {

        if (active && payload && payload.length > 0 && payload[0].payload != null ) {
            let closeTip = <div></div>
            let upperYip = <div></div>
            let lowerTip = <div></div>
            let middleTip = <div></div>
            let emaTip = <div></div>
            let smaTip = <div></div>
    
            if(bb){
                upperYip =  <div>Upper: {payload[0].payload.upper.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                lowerTip = <div>Middle:  {payload[0].payload.middle.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                middleTip = <div>Lower: {payload[0].payload.lower.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            }else{}

            if(ema){
                emaTip =  <div>EMA: {payload[0].payload.ema.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            }else{}
    
            if(sma){
                emaTip =  <div>SMA: {payload[0].payload.ema.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            }else{}
    
      
                return (
                    <div>
                    <div>Close: {payload[0].payload.c.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    {upperYip}
                    {middleTip}
                    {lowerTip}
                    {emaTip}
                    {smaTip}
                    </div>
                );

    
            if(payload[0].payload.upper != null){
                return (
                    <div>{payload[0].payload.upper.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                );
            }else{}
    
    
        }
    
        return null;
    };

    ///state required for zoom

    const fetchSymbols = async () => {
        try {
            if (props.hasEnoughBalance) { // TODO change to 90 cope

                const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/call-analyser');

                // let symbolNameObjs:any = new Array()
                // if(response!=null && response.length>0){
                //     response.map((u:any)=>{
                //         symbolNameObjs.push({name:u, value:u})
                //     });
                //     console.log('symbol objs',symbolNameObjs);
                //     setSymbols(symbolNameObjs);
                // }else{}
                if (response != null && response.length > 0) {
                    setSymbols(response);
                    setSymbolSelected(symbols[0]);
                 
                } else { }


            } else { }




        } catch (e) {
            console.log(e);
        }

    }



    const fetchData = async () => {
        try {
            if (props.hasEnoughBalance) {
                const { data: response } = await axios.get(process.env.REACT_APP_PROXY+'/call-analyser/' + symbolSelected);
                setOHLCV(response);

                const labelVals: any = new Array();
                let openPriceVals:any = new Array();
                let closePriceVals: any = new Array();
                let highPriceVals: any = new Array();
                let lowPriceVals:any = new Array();
                let volumeVals:any = new Array();


                const lineDataVals: any = [{
                    "id": "calls",
                    "color": "hsl(135, 70%, 50%)",
                    "data": []
                }]
                if (response != null && response.length > 0) {
                    try {

                        response.map((r: any) => {

                            labelVals.push(r.time);
                            closePriceVals.push(r.c);
                            openPriceVals.push(r.o);
                            highPriceVals.push(r.h);
                            lowPriceVals.push(r.l);
                            volumeVals.push(r.v);

                            lineDataVals[0].data.push({ x: r.time, y: r.c, tweets: r.tweets });
                        })

                        const bbInput = {
                            period: period,
                            values: closePriceVals,
                            stdDev: 2

                        }

                        let maPeriod = 20
                        const emaInput = {
                            period: maPeriod,
                            values: closePriceVals,

                        }
                        
                        const smaInput = {
                            period: maPeriod,
                            values: closePriceVals,

                        }

                        var vwapInput = {
                            open : openPriceVals,
                            high : highPriceVals,
                            low : lowPriceVals,
                            close : closePriceVals,
                            volume : volumeVals
                          };
                        //If BB selected 
                        const BBRes = BB.calculate(bbInput);
                        const emas = EMA.calculate(emaInput);
                        const smas = SMA.calculate(smaInput);
                        console.log(vwapInput)
                        const vwaps = VWAP.calculate(vwapInput);

                        closePriceVals = closePriceVals.sort();
                        let firstValue = closePriceVals[0];
                        firstValue = firstValue*0.85;

                        let lastValue = closePriceVals[closePriceVals.length-1];
                        lastValue = lastValue * 1.85
                        
                        setDomainStart(firstValue);
                        setDomainEnd(lastValue)

                          maPeriod = period
                        if (BBRes != null && BBRes.length > 0) {

                            var j = 0;
                            for (var i = maPeriod; i < response.length; i++) {


                                response[i].middle = BBRes[j].middle;
                                response[i].lower = BBRes[j].lower;
                                response[i].upper = BBRes[j].upper;
                                response[i].pb = BBRes[j].pb
                                response[i].ema = emas[j];
                                response[i].sma = smas[j];
                                response[i].vwap = vwaps[j];
                                j++


                            }
                            /// TODO - neeed to send back timeseries + period to calc relevant indicators 
                            // consider a seperate view.. 
                            // add buttons which enable different TA

                            let closePriceMax: any = _.max(closePriceVals);
                            let upperMax: any = _.maxBy(response, 'upper');
    
                            setDomainEnd(Math.max(closePriceMax, upperMax.upper))
                            response.splice(0, maPeriod);

                        } else { }



                        setLabels(labelVals);
                        setClosePrices(closePriceVals);
                        setLineData(response);

                        

                    } catch (e) {
                        console.log(e);
                    }

                } else { }

            } else { }
        } catch (e) {
            console.log('error');
            console.log(e);
        }

    }

    useEffect(() => {

        fetchSymbols();

    }, [props.hasEnoughBalance]);

    useEffect(() => {

        fetchData();

    }, [props.hasEnoughBalance, symbolSelected]);  // TODO watch symbol select to trigger this


    // const CustomTooltip = (active:any, payload:any, label:any )  => {
    //     if (active && payload && payload.length>0) {
    //         console.log(payload,label)
    //         return (
    //             <div>{payload[0].value}</div>
    //             // <TweetEmbed id={'1479307070285520899'}  options={{cards: 'hidden',theme: 'dark', conversation: 'none'}}/> 
    //         );
    //     }else{
    //         return <div></div>
    //     }

    //     return null;
    // };



    const SymbolComboBox = () => {
        return (
            <Autocomplete
                disablePortal

                id="Symbols"
                options={symbols}
                sx={{ width: 200 }}
                // defaultValue={symbols.find( (v:any) => String(v) === String(symbolSelected))}
                defaultValue={symbolSelected}

                renderInput={(params) => <TextField {...params} label="Markets" />}
                onChange={onSymbolSelect}
            />
        );
    }

    const TechIndicatorComboBox = () => {
        return (
            <Autocomplete
                disablePortal

                id="techIndicator"
                options={techIndicatorValues}
                sx={{ width: 200 }}
                // defaultValue={symbols.find( (v:any) => String(v) === String(symbolSelected))}
                defaultValue={selectedTI}

                renderInput={(params) => <TextField {...params} label="Technical Indicators" />}
                onChange={onTISelect}
            />
        );
    }

    const onSymbolSelect = (event: any, value: any) => {

        if (values != null) {

            setCalls([]);
            setSymbolSelected(value);
        } else { }

    }

    const onTISelect = (event: any, value: any) => {

        if (values != null) {
            setSelectedTI(value);
            if(value==='Bollinger Bands'){
                setBB(true);
            }else{
                setBB(false);
            }

            if(value==='EMA20'){
                setEMA(true);
            }else{
                setEMA(false);
            }

            if(value==='SMA20'){
                setSMA(true);
            }else{
                setSMA(false);
            }

            if(value==='VWAP'){
                setVWAP(true);
            }else{
                setVWAP(false);
            }
   
        } else { }

    }

    const formatXAxis = (tickItem: any) => {
        try {

            return moment(tickItem).format("DD/MMM/YYYY");
        } catch (e) {
            return tickItem;
        }

        //return tickItem.toLocaleDateString();
    }

    const formatYAxis = (tickItem: any) => {
        try {
            return tickItem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } catch (e) {
            return tickItem;
        }

    }

    const zoom = (e: any) => {

        if (zoomIn) {
            setZoomIn(false);
            fetchData();
        } else { }

        if (!zoomIn) {
            setZoomIn(true);
        } else { }
    }


    return (

        <div>
            {!props.hasEnoughBalance ? <div>Connect wallet to view this page. You must also have 90 COPE or more to view this page. Your balance is : {props.copeBalance}</div> :
                <div>
                    <Typography>
                        Hover over the green circles to view $COPE Leaderboard calls by rank. Click Zoom and drag the brush to hone in on a date range.
                    </Typography>
                    <div><p></p></div>
                    <Grid container gap={2}>
                        <Grid item> <SymbolComboBox /> </Grid>
                        <Grid item> <TechIndicatorComboBox /> </Grid>
                    </Grid>
                    
                    <Button onClick={zoom}>{!zoomIn ? 'Zoom In' : 'Zoom Out'}</Button>

                    <Typography>
                        &nbsp;
                    </Typography>

                    <Grid container>
                        <Grid item>

                            {domainEnd > 0?
                                <LineChart
                                    
                                    width={1200}
                                    height={350}
                                    data={lineData}
                                    margin={{
                                        top: 5,
                                        right: 5,
                                        left: 15,
                                        bottom: 5,
                                    }}


                                    onMouseMove={(props: any) => {
                                        // We get the values passed into the onMouseMove event 


                                        if (props != null && props.isTooltipActive
                                            && props.activePayload != null
                                            && props.activePayload.length > 0
                                            && props.activePayload[0].payload != null
                                            && props.activePayload[0].payload.tweets != null
                                            && props.activePayload[0].payload.tweets.length > 0) {

                                            let sortedCalls: any = _.sortBy(props.activePayload[0].payload.tweets, ['rank']);
                                            setCalls(sortedCalls);
                                            // If the tooltip is active then we display the Y value 
                                            // under the mouse using our custom mapping

                                        }
                                    }}
                                >

                                    <XAxis dataKey="time" tickFormatter={formatXAxis} tick={{ fill: 'white', fontSize:'12' }} />
                                    <YAxis type='number' dataKey="c" tick={{ fill: 'white', fontSize:'14' }} domain={[domainStart, Math.round(domainEnd)]} tickFormatter={formatYAxis} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    <Line type="monotone"   dataKey="c" name={'Close Price'} stroke="#8884d8" activeDot={{ r: 8 }} dot={<CustomizedDot />} />
                          
                                    <Line type="monotone" dataKey="upper" name={'upper'} stroke="green" dot={<div></div>} hide={!bb}/>
                                    <Line type="monotone" dataKey="middle" name={'middle'} stroke="yellow" dot={<div></div>} hide={!bb}/>
                                    <Line type="monotone" dataKey="lower" name={'lower'} stroke="red" dot={<div></div>} hide={!bb}/>
                                    
                                    <Line type="monotone" dataKey="ema" name={'EMA'} stroke="cyan" dot={<div></div>}  hide={!ema} />
                                    <Line type="monotone" dataKey="sma" name={'SMA'} stroke="orange" dot={<div></div>}  hide={!sma} />

                                    {/* <Line type="monotone" dataKey="vwap" name={'VWAP'} stroke="blue" dot={<div></div>}  hide={!vwap} /> */}

                                    {zoomIn ? <Brush fill="black" dataKey="time" tickFormatter={formatXAxis} /> : <div></div>}

                                </LineChart>
                                : <div></div>}

                        </Grid>
                    </Grid>


                    <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid">
                        {calls.map((t: any) => {


                            return <Box key={t.tweetLink} sx={{ m: 2 }}><TraderCallCard key={t.tweetLink} id={t.tweetLink.split('/status/')[1]} call={t.call} rank={t.rank} /></Box>
                        })}
                    </Masonry>


                </div>
            }
        </div>
    )





}